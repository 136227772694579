import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import {injectIntl} from 'react-intl';

import fixInternalLinks from '../../../helpers/fixInternalLinks'
import Image from "../Image";

const ProductTitle = styled.h3`
  ${tw`font-montserrat font-medium text-xl leading-tight text-black m-0 mt-5 text-center`}

  p {
    margin: 0px;
  }

  @media screen and (max-width: 64em) {
    margin: 0 1.25rem;
  }
`

const ProductLink = styled(Link)`
  ${tw`no-underline text-center`}

  :hover ${ProductTitle},
  :focus ${ProductTitle} {
    text-decoration: underline;
  }
`

const ProductImageWrapper = styled.div`
  height: 270px;
  position: relative;
  ${tw`flex items-center`}

  img {
    margin: 0 auto;
    height: 270px;
    object-fit: contain;
    object-position: center;
    font-family: 'object-fit: contain; object-position: center';
  }
`

const ProductBlock = (props) => {
  const { title, alt, guid, image, intl } = props;
  return (
    <ProductLink to={fixInternalLinks(guid, intl.locale)}>
      <ProductImageWrapper>
        <Image url={image} alt={`${alt} pack`} sizes="(min-width: 64em) 300px, 100vw" />
      </ProductImageWrapper>

      <ProductTitle
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </ProductLink>
  )
}

ProductBlock.propTypes = {
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  // Fixed image
  image: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired
}

ProductBlock.defaultProps = {}

export default injectIntl(ProductBlock)
