import React from 'react'
import PropTypes from 'prop-types'

import sendEvent from '../../../helpers/sendAnalytics';
import Button from '../../common/Button'

const LinkBlock = (props) => {
  const { title, link, modification, appnexus, ga, gtag, snapchat } = props;
  return (
    <Button
      to={link}
      convert={false}
      modification={modification}
      dangerouslySetInnerHTML={{ __html: title }}
      onClick={()=>sendEvent({appnexus,ga,snapchat,gtag})}
    />
  )
}

LinkBlock.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  modification: PropTypes.string,

  appnexus: PropTypes.string,
  ga: PropTypes.objectOf({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string
  }),
  snapchat: PropTypes.string,
  gtag: PropTypes.objectOf({
    send_to: PropTypes.string
  })
}

LinkBlock.defaultProps = {
  modification: null,
  appnexus: '',
  ga: {
    category: '',
    action: '',
    label: ''
  },
  gtag: {
    send_to: null
  },
  snapchat: ''
}

export default LinkBlock
