import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {RichText} from 'prismic-reactjs';

import dot from '../../../assets/images/dot.svg';

const BulletListWrapper = styled.div`
  ul {
    columns: 2;
    ${tw`list-reset m-0 mb-10`}

    li {
      ${tw`font-raleway text-blog-text text-lg leading-loose pl-4 bg-no-repeat`}
      background-image: url(${dot});
      background-position: 0px 15px;

      p {
        margin: 0px;
      }

      a {
        ${tw`text-border-blue no-underline hover:underline focus:underline`}
      }
    }
  }

  @media screen and (max-width: 64em) {
    ${tw`-ml-5 -mr-5`}

    ul {
      columns: 1;

      li {
        ${tw`ml-5 mr-5`}
      }
    }
  }
`

const BulletListHeader = styled.h3`
  ${tw`font-montserrat font-normal text-2xl text-black block w-full border-0 border-border-blue border-b border-solid pb-6 m-0 mb-6`}
  line-height: 29px;

  @media screen and (max-width: 64em) {
    ${tw`px-5`}
  }
`

const BulletListBlock = (props) => {
  const { title, list, modification } = props;
  return (
    <BulletListWrapper modification={modification}>
      <BulletListHeader>{title}</BulletListHeader>

      <ul>
        {list && list.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            <RichText render={item.item} />
          </li>
        ))}
      </ul>
    </BulletListWrapper>
  )
}

BulletListBlock.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf([
    PropTypes.objectOf({
      item: PropTypes.arrayOf([
        PropTypes.objectOf({
          type: PropTypes.string,
          text: PropTypes.string
        })
      ])
    })
  ]).isRequired,
  modification: PropTypes.string
}

BulletListBlock.defaultProps = {
  modification: ''
}

export default BulletListBlock
