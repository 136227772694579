import React, { useState } from "react";
import styled from "@emotion/styled";
import { useSwipeable } from 'react-swipeable'
import Image from '../Image'

const StackCarouselWrapper = styled.div`
  position: relative;
  height: 400px;
  perspective: 1000px;
  transform-style: preserve-3d;
  margin-bottom: 50px;
  overflow: hidden;

  img {
    margin: auto;
    width: 60%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
    opacity: 0;
    transform: translate3d(0,0,-700px);
    object-fit: contain;
    user-select: none;
    z-index: 1;

    &.active {
      opacity: 1;
      z-index: 2;
    }

    &.prev,
    &.next {
      opacity: 1;
    }

    &.prev {
      transform: translate3d(-60%,0,-700px);
    }

    &.next {
      transform: translate3d(60%,0,-700px);
    }

    &.active {
      transform: translate3d(0,0,0);
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
    margin: 0 5px;

      button {
        border: 0;
        background: none;
        display: block;
        border-radius: 100%;
        width: 16px;
        height: 16px;
        background-color: #e2e3e3;
        transition: all 250ms ease-in-out;
        cursor: pointer;
        padding: 0;

        &.active {
          width: 20px;
          height: 20px;
          background-color: #00acee;
        }
      }
    }
  }
`

const StackCarouselDragHandler = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  cursor: grab;
`

const StackCarousel = (props) => {
  const [currentSlide, setSlide] = useState(1);
  const { images } = props;

  const slideClass = (index) => {
    if (index === currentSlide) {
      return 'active';
    }

    if ((index + 1) === currentSlide) {
      return 'prev';
    }

    if ((index - 1) === currentSlide) {
      return 'next';
    }

    return null;
  }

  const dragHandler = useSwipeable(
    {
      onSwipedLeft:() => {
        if (currentSlide === images.length - 1) {
          setSlide (0);
        } else {
          setSlide(currentSlide + 1)
        }
      },
      onSwipedRight:() => {
        if (currentSlide === 0) {
          setSlide (images.length - 1);
        } else {
          setSlide(currentSlide - 1)
        }
      },
      trackMouse: true,
      trackTouch: true
    }
  )

  return (
    <StackCarouselWrapper>
      <StackCarouselDragHandler {...dragHandler} />
      {images.map((image, index) =>
        (
          <Image
            url={image}
            key={image}
            alt="Royale"
            sizes="200px"
            className={slideClass(index)}
          />
        )
      )}
      <ul>
        {images.map((image, index) =>
          (
            <li>
              <button onClick={() => setSlide(index)} type="button" className={(index === currentSlide) ? 'active' : null} aria-label={`Go to slide ${index}`} />
            </li>
          )
        )}
      </ul>
    </StackCarouselWrapper>
  )
}

export default StackCarousel;
