import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const getHeader = (type) => {
  switch (type.toLowerCase()) {
    case 'h1':
      return styled(type.toLowerCase())`
        ${tw`font-montserrat font-light text-5xl m-0 mb-6 break-words`}
        p {
          margin: 0px;
        }
`
    case 'h2':
      return styled(type.toLowerCase())`
        ${tw`font-montserrat font-light text-4xl m-0 mb-6 break-words`}
        p {
          margin: 0px;
        }
`
    case 'h3':
      return styled(type.toLowerCase())`
        ${tw`font-montserrat font-light text-3xl m-0 mb-5 break-words`}
        p {
          margin: 0px;
        }
`
    case 'h4':
      return styled(type.toLowerCase())`
        ${tw`font-montserrat font-light text-2xl m-0 mb-4 break-words`}
        p {
          margin: 0px;
        }
`
    case 'h5':
      return styled(type.toLowerCase())`
        ${tw`font-montserrat font-light text-xl m-0 mb-4 break-words`}
        p {
          margin: 0px;
        }
`
    case 'h6':
      return styled(type.toLowerCase())`
        ${tw`font-montserrat font-light text-lg m-0 break-words`}
        p {
          margin: 0px;
        }
`
    default:
      return styled.h2`
        ${tw`font-montserrat font-light text-4xl m-0 break-words`}
        p {
          margin: 0px;
        }
`
  }
}

const HeaderBlock = (props) => {
  const { type, title, modification } = props;
  const Component = getHeader(type)
  return <Component dangerouslySetInnerHTML={{ __html: title }} modification={modification} />
}

HeaderBlock.propTypes = {
  modification: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

HeaderBlock.defaultProps = {
  modification: null
}

export default HeaderBlock
