import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Flex, Box } from '../../common/Grid'
import Container from '../../common/Container'
import Divider from '../../common/Divider'

import {
  TextBlock,
  LinkBlock,
  HeaderBlock,
  ImageBlock,
  VideoBlock
} from '..'

const VideoLeftTextRightWrapper = styled.div`
  ${tw`mb-20`}

  :lang(zh-hans) .gatsby-image-wrapper,
  :lang(zh-hant) .gatsby-image-wrapper {
    width: 375px;
  }

  :lang(zh-hans) .image,
  :lang(zh-hant) .image {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  :lang(zh-hans) h2,
  :lang(zh-hant) h2 {
    font-family: "Montserrat", "DejaVu Sans", Verdana, sans-serif;
    font-weight: 400;
  }

  @media screen and (max-width: 64em) {
    :lang(zh-hans) .gatsby-image-wrapper,
    :lang(zh-hant) .gatsby-image-wrapper {
      margin-bottom: 64px;
    }
  }
`

const VideoLeftTextRightFigure = styled.figure`
  margin: 0;
  img {
    width: 100%;
  }
  figcaption {
    ${tw`font-raleway pt-2 text-xs`}
    color: #4F5354;
  }
`

const VideoLeftTextRight = (props) => {
  const { modification, video, right, className, grid } = props;

  let paddingRight = 0;
  if (modification.includes('fullimage')) {
    paddingRight = [0,0,0,"20%"];
  }

  let leftGrid = [1,1/2,1/2,1/2];
  let rightGrid = [1,1/2,1/2,1/2];

  if (grid === 'onethird') {
    leftGrid = [1,2/3,2/3,2/3];
    rightGrid = [1,1/3,1/3,1/3];
  }

  if (grid === 'twothird') {
    leftGrid = [1,1/3,1/3,1/3];
    rightGrid = [1,2/3,2/3,2/3];
  }

  return (
    <VideoLeftTextRightWrapper modification={modification} className={className} {...props}>
      <Container modification={modification} width={(modification.includes('fullimage')) ? '100%': "1240px"}>
        <Flex alignItems="center" flexWrap="wrap" flexDirection={['column-reverse', 'row', 'row', 'row']} mx={[0, -4, -4, -4]}>
          <Box width={leftGrid} px={[0, 4, 4, 4]} mb={[5,0,0,0]} className="video">
            { video ?
              (
                <VideoLeftTextRightFigure>
                  <VideoBlock url={video} />
                </VideoLeftTextRightFigure>
              )
              : null }
          </Box>
          <Box width={rightGrid} px={[0,4,4,4]} pr={paddingRight}>
            {right.map((block) => {
              // eslint-disable-next-line dot-notation
              switch (block['__typename']) {
                case 'DividerBlock':
                  return <Divider color={block.color} key={block.guid} />
                case 'TextBlock':
                  return <TextBlock modification={block.modification} text={block.text} key={block.guid} />
                case 'LinkBlock':
                  return <LinkBlock modification={block.modification} title={block.title} link={block.link} key={block.guid} snapchat={block.snapchat} gtag={block.gtag} ga={block.ga} appnexus={block.appnexus} />
                case 'HeaderBlock':
                  return <HeaderBlock modification={block.modification} title={block.title} type={block.type} key={block.guid} />
                case 'ImageBlock':
                  return <ImageBlock modification={block.modification} image={block.image_url} key={block.guid} inside />
                default:
                  // eslint-disable-next-line dot-notation
                  console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
              }

              return null
            })}
          </Box>
        </Flex>
      </Container>
    </VideoLeftTextRightWrapper>
  )
}

VideoLeftTextRight.propTypes = {
  modification: PropTypes.string,
  Video: PropTypes.string.isRequired,
  right: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired,
  caption: PropTypes.string,
  className: PropTypes.string,
  grid: PropTypes.string
}

VideoLeftTextRight.defaultProps = {
  modification: '',
  caption: '',
  className: '',
  grid: 'half'
}

export default VideoLeftTextRight
