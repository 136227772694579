import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledDivider = styled.hr`
  background-color: ${props => (props.color) ? props.color : '#000'};
  max-width: 50px;
  width: 100%;
  height: 2px;
  margin: 20px 100% 20px 0;
`

const Divider = (props) => {
  return (
    <StyledDivider {...props} />
  )
}

Divider.propTypes = {
  color: PropTypes.string.isRequired
}

export default Divider;
