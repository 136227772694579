/* eslint-disable */
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import {injectIntl} from 'react-intl';
import { Flex, Box } from '../../common/Grid'
import fixInternalLinks from '../../../helpers/fixInternalLinks'
import Icon from '../../common/Icon'
import Image from '../../common/Image'
import Napkins from '../../../assets/images/napkins/napkins-new2024-en.jpg'
import NapkinsFr from '../../../assets/images/napkins/napkins-new2024-fr.jpg'
import HomeSlide1 from '../../../assets/images/Bath_Web_Velour_435x420px.jpg'
import HomeSlide2 from '../../../assets/images/homep-22-en.webp'
import HomeSlide3 from '../../../assets/images/homep-32-en.jpg'
import HomeSlide1Fr from '../../../assets/images/Bath_Web_Velour_435x420px.jpg'
import HomeSlide2Fr from '../../../assets/images/homep-22-fr.webp'
import HomeSlide3Fr from '../../../assets/images/homep-32-fr.jpg'
import Hover1 from '../../../assets/images/kitten_roll.webp'
import Hover2 from '../../../assets/images/royale_tiger_towel_image.webp'
import Hover3 from '../../../assets/images/2ply_facial_tissue_image.webp'
import Hover4 from '../../../assets/images/napkins_image.webp'

const pics = [HomeSlide1,HomeSlide2,HomeSlide3,Napkins]
const picsFr = [HomeSlide1Fr,HomeSlide2Fr,HomeSlide3Fr,NapkinsFr]

const hoverPics = [Hover1,Hover2,Hover3,Hover4]


const ProductCategoriesWrapper = styled.section`
  ${tw`px-2 block`}
  max-width: 1260px;
  margin: 120px auto;

  @media (max-width: 1280px) {
    margin: 60px auto;
  }
`

const ProductCategoriesGrid = styled.div`
  ${tw`flex flex-wrap -mx-2`}
`

const ProductCategory = styled.div`
  ${tw`w-1/4 px-2`}

  @media (max-width: 1280px) {
    ${tw`w-1/2 px-2 mb-10`}
  }

  @media (max-width: 900px) {
    ${tw`w-full px-0 mb-5`}
  }
`

const ProductCategoryLink = styled(Link)`
  ${tw`text-black block no-underline`}

  @media (max-width: 900px) {
    ${tw`flex items-center`}
  }
`

const ProductHoverBG = styled.div`
  ${tw`relative flex items-center justify-center overflow-hidden`}
  width: 300px;
  height: 280px;
  transition: all 500ms ease;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    object-position: center;
    font-family: 'object-fit: cover; object-position: center';
    width: 100%;
    height: 100%;
  }

  @media (max-width: 900px) {
    width: 160px;
    height: 150px;
    background-size: cover;
  }

  @media (max-width: 400px) {
    width: 100px;
    height: 90px;
    background-size: cover;
  }
`

const ProductHoverLayer = styled.div`
  ${tw`absolute pin opacity-0`}
  background-color: rgba(77, 141, 205, .78);
`

const ProductHoverFadeUp = styled.div`
  ${tw`absolute pin-x pin-b block ml-auto mr-auto opacity-0`}
  width: 220px;
  -webkit-transform: translate(0px, 20px);
  transform: translate(0px, 20px);
  transition: all 700ms cubic-bezier(.55, .085, .68, .53);

  @media (max-width: 1280px) {
    ${tw`ml-0`}
  }
`

const ProductHover = styled.div`
  ${tw`relative flex items-center justify-center`}
  height: 280px;

  &:focus,
  &:hover {
    ${ProductHoverBG} {
      width: 220px;
      height: 220px;
      border-radius: 100%;
    }

    ${ProductHoverLayer} {
      opacity: 1;
    }

    ${ProductHoverFadeUp} {
      opacity: 1;
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }

  @media (max-width: 1280px) {
    ${tw`relative flex items-center justify-center`}
  }

  @media (max-width: 900px) {
    width: 160px;
    height: 150px;

    ${ProductHoverLayer},
    ${ProductHoverFadeUp} {
      display: none;
    }

    &:focus,
    &:hover {
      ${ProductHoverBG} {
        border-radius: 0px;
        height: 150px;
        width: 160px;
      }
    }
  }

  @media (max-width: 400px) {
    width: 100px;
    height: 90px;

    ${ProductHoverLayer},
    ${ProductHoverFadeUp} {
      display: none;
    }

    &:focus,
    &:hover {
      ${ProductHoverBG} {
        border-radius: 0px;
        height: 90px;
        width: 100px;
      }
    }
  }
`

const ProductHoverImage = styled(Image)`
  ${tw`ml-auto mr-auto block`}
`

const ProductCategoryH2 = styled.h2`
  ${tw`font-montserrat text-3xl font-medium mb-0`}
  margin-left: 15px;
  line-height: 34px;
  letter-spacing: 0.44px;

  @media (max-width: 1280px) {
    margin-left: 0;
    text-align: center;
  }

  @media (max-width: 900px) {
    ${tw`mt-0 ml-2 text-left`}
  }
`

const ProductCategoriesHeader = styled.h2`
  ${tw`font-montserrat text-4xl font-light mb-0 text-black`}
  max-width:350px;

  p {
    margin: 0px;
  }
`

const ProductCategoriesTitle = styled(Flex)`
  ${tw`mb-10`}
`

const ProductCategoriesBlock = ({title, title_html, modification, products, intl}) => {
  return (
    <ProductCategoriesWrapper>
      {
        title && modification === 'room'
          ?
          (
            <ProductCategoriesTitle alignItems="center">
              <Box>
                <Icon icon="room" width={154} />
              </Box>
              <Box pl={4}>
                <ProductCategoriesHeader dangerouslySetInnerHTML={{__html: title_html}} />
              </Box>
            </ProductCategoriesTitle>
          )
          :
          null
      }
      <ProductCategoriesGrid>
        {products.map((product, index) => (

          <ProductCategory key={product.title}>
            <ProductCategoryLink to={fixInternalLinks(product.guid, intl.locale)}>
              <ProductHover>
                <ProductHoverBG>
                  { index === 3 ?
                    <img src={intl.locale === 'fr' ? NapkinsFr:Napkins} alt="Napkins" />
                    :
                    <img src={intl.locale === 'fr' ? picsFr[index]:pics[index]} alt=""/>
                  }

                  <ProductHoverLayer />
                </ProductHoverBG>
                <ProductHoverFadeUp>
                  <ProductHoverImage
                    url={hoverPics[index]}
                    sizes="(min-width: 64em) 220px, 100vw"
                    alt={product.title}
                  />
                </ProductHoverFadeUp>
              </ProductHover>
              <ProductCategoryH2 dangerouslySetInnerHTML={{ __html: product.title }} />
            </ProductCategoryLink>
          </ProductCategory>
        ))}
      </ProductCategoriesGrid>
    </ProductCategoriesWrapper>
  )
};

ProductCategoriesBlock.propTypes = {
  // Any additional menu elements (used for homepage)
  products: PropTypes.arrayOf(PropTypes.shape({
    guid: PropTypes.string.isRequired,
    // Name of the product
    name: PropTypes.string.isRequired,
    // Product image
    image: PropTypes.string.isRequired,
    // Product fade up image
    hoverimage: PropTypes.string.isRequired,
    // Product link
    link: PropTypes.string.isRequired
  })).isRequired
}

export const productCategoriesBlockFragment = graphql`
  fragment productCategoriesBlockFragment on ProductCategoriesBlock {
    id
    modification
    title
    title_html
    product_1 {
      title
      routeSegment
      guid
      preview_image_url
      preview_hover_image_url
    }

    product_2 {
      title
      routeSegment
      guid

      preview_image_url
      preview_hover_image_url
    }

    product_3 {
      title
      routeSegment
      guid

      preview_image_url
      preview_hover_image_url
    }

    product_4 {
      title
      routeSegment
      guid

      preview_image_url
      preview_hover_image_url
    }
  }
`

export default injectIntl(ProductCategoriesBlock)
