import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import striptags from 'striptags'
import {RichText} from 'prismic-reactjs';
import { renderToString } from 'react-dom/server'

import { Flex, Box } from '../../common/Grid'

const CouponContainer = styled.div`
  ${tw`mt-12 mb-12 pt-12 pb-12`}
`

const Container = styled.div`
  width: 100%;
  max-width: 1048px;
  margin: 0 auto;

  @media screen and (max-width: 64em) {
    ${tw`px-5`}
  }
`

const CouponHeader = styled.h3`
  ${tw`font-montserrat text-5xl font-light leading-none text-black m-0 mb-5`}

  p {
    margin: 0px;
  }

  .sup {
    font-size: 50%;
    line-height: 0;
    vertical-align: baseline;
    position: relative;
    top: -0.7em;
  }
`

const CouponSubHeader = styled.h5`
  ${tw`font-montserrat text-xl font-normal leading-none text-black m-0 mb-3`}

  p {
    margin: 0px;
  }

  .sup {
    ${tw`text-xl`}
    vertical-align: top;
    top: 0px;
  }
`

const CouponBody = styled.div`
  ${tw`font-raleway text-lg font-normal leading-normal text-black m-0 mb-6`}

  p {
    margin: 0px;
  }
`

const CouponImage = styled.img`
  max-width: 300px;
  margin-left: auto;

  @media screen and (max-width: 64em) {
    max-width: 100%;
  }
`

const CouponBlock = (props) => {
  const { title, subtitle, body, image } = props;

  return (
    <CouponContainer>
      <Container>
        <Flex flexWrap="wrap">
          <Box width={[1, 1, 1, 1 / 2]} mb={[5, 5, 5, 0]}>
            <CouponSubHeader dangerouslySetInnerHTML={{__html:striptags(title[0].text, ['span','sup'])}} />
            <CouponHeader dangerouslySetInnerHTML={{__html:striptags(renderToString(<RichText render={subtitle} />), ['span','sup'])}} />
            <CouponBody><RichText render={body} /></CouponBody>
          </Box>

          <Box width={[1, 1, 1, 1 / 2]}>
            {image ? <CouponImage src={image.url} alt={image.alt ? image.alt : ''} /> : null}
          </Box>
        </Flex>
      </Container>
    </CouponContainer>
  )
}

CouponBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired
}

// CouponBlock.defaultProps = {
//   eventName: ''
// }

export default CouponBlock
