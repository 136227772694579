import React, {Component} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import {injectIntl} from 'react-intl';
import Container from '../../common/Container'

const ProductLocatorContainer = styled.div`
  ${tw`text-center my-32`}
  margin: 30px 0;
  min-height: 775px;
`

const MarginContainer = styled(Container)`
  ${tw`my-32`}
`

const ReviewTitle = styled.h2`
  ${tw`font-light font-montserrat text-black text-3xl leading-tight m-0 mb-8`}
`

class ProductLocatorBlock extends Component {
  componentDidMount() {
    const { upc, modification, intl } = this.props;
    if (modification !== 'napkins') {
      const url = `https://destinilocators.com/irvingconsumerproducts/site/install/?LABEL=${modification}&PROD=${upc.join(',')}&DEFLANG=${intl.locale === 'en' ? '1' : '2'}`
      // Prevents error when building static site
      try {
        // eslint-disable-next-line
        const postscribe = require('postscribe');
        postscribe(this.instance, `<script type="text/javascript" src="${url}"></script>`)
      } catch (e) {
        // Do nothing
      }
    }
  }

  render() {
    const { page, modification, intl } = this.props;

    if (page && page.product_code) {
      const productCode = page.product_code.split('|');
      const variant = productCode[1];

      return (
        <div>
          {modification !== 'napkins' ? (
            <ProductLocatorContainer>
              <div
                ref={el => {
                  this.instance = el
                }}
                id="destini-holder"
              />
            </ProductLocatorContainer>
          ) : null}

          <MarginContainer id="bazaarvoice">
            <ReviewTitle>{intl.locale === 'en' ? 'Customer Reviews' : 'Avis des utilisateurs'}</ReviewTitle>
            <div
              data-bv-show="reviews"
              data-bv-product-id={variant}
              id="review-holder"
            />
            <div
              data-bv-show="questions"
              data-bv-product-id={variant}
            />
          </MarginContainer>
        </div>
      )
    }

    if (modification !== 'napkins') {
      return (
        <div>
          <ProductLocatorContainer>
            <div
              ref={el => {
                this.instance = el
              }}
            />
          </ProductLocatorContainer>
        </div>
      )
    }

    return null
  }
}

ProductLocatorBlock.propTypes = {
  upc: PropTypes.string
}

ProductLocatorBlock.defaultProps = {
  upc: ''
}

export const productLocatorBlockFragment = graphql`
  fragment productLocatorBlockFragment on ProductLocatorBlock {
    id
    name
    upc
    modification
  }
`

export default injectIntl(ProductLocatorBlock)
