import PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";

import Container from "../../common/Container";
import StackCarousel from "../../common/StackCarousel";
import { Box, Flex } from "../../common/Grid";
import {
  HeaderBlock,
  ImageBlock,
  LinkBlock,
  TextBlock
} from "../index";

const SliderTextBlockWrapper = styled.div`
  ${tw`relative`}

  @media(max-width:920px) {
    margin-bottom: 50px;
  }
`

const SliderTextBlock = (props) => {
  const { modification, images, content, reverse } = props;

  let direction = ['column', 'column', 'column', 'row'];
  if (reverse) {
    direction = ['column-reverse', 'column-reverse', 'column-reverse', 'row'];
  }

  return (
    <SliderTextBlockWrapper modification={`${modification}`}>
      <Container width={1040}>
        <Flex modification={modification} flexWrap="wrap" alignItems="center" flexDirection={direction}>
          <Box
            width={[
              1,
              1,
              1,
              1 / 2
            ]}
            px={[
              0,
              0,
              2
            ]}
            mb={[
              5,
              5,
              5,
              0
            ]}
            className="image"
          >
            <StackCarousel images={images} />
          </Box>

          <Box
            width={[
              1,
              1,
              1,
              1 / 2
            ]}
            px={[
              0,
              0,
              0,
              2
            ]}
          >

            {content.map((block) => {
              // eslint-disable-next-line dot-notation
              switch (block['__typename']) {
                case 'TextBlock':
                  return <TextBlock modification={block.modification} text={block.text} key={block.guid} />
                case 'LinkBlock':
                  return <LinkBlock backgroundColor={block.backgroundColor} modification={block.modification} title={block.title} link={block.link} key={block.guid} />
                case 'HeaderBlock':
                  return <HeaderBlock modification={block.modification} title={block.title} type={block.type} key={block.guid} />
                case 'ImageBlock':
                  return <ImageBlock modification={block.modification} image={block.image_url} key={block.guid} inside />
                default:
                  // eslint-disable-next-line dot-notation
                  console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
              }

              return null
            })}
          </Box>
        </Flex>
      </Container>
    </SliderTextBlockWrapper>
  )
}

SliderTextBlock.propTypes = {
  modification: PropTypes.string,
  images: PropTypes.arrayOf([
    PropTypes.string
  ]).isRequired,
  content: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired
}

SliderTextBlock.defaultProps = {
  modification: ''
}

export default SliderTextBlock
