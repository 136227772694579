import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

import { Flex, Box } from '../../common/Grid'
import ProductBlock from '../../common/ProductBlock'

const AlsoBoughtHeader = styled.h2`
  ${tw`font-light font-montserrat text-black text-4xl leading-tight m-0 mb-8`}
  max-width: 700px;

  p { margin: 0; }
  p strong {
    ${tw`font-light`}
  }
  @media screen and (max-width: 64em) {
    ${tw`text-2xl`}
  }
`

const AlsoBoughtBlock = (props) => {
  const { title, products } = props;
  return (
    <div style={{marginTop:'8%'}}>
      <AlsoBoughtHeader dangerouslySetInnerHTML={{ __html: title }} />
      <div style={{display:'block',width:'100%',height:'50px'}} />
      <Flex flexWrap="wrap">
        <Box width={[1,1,1,1/3]} px={[0,0,0,4]} mb={[5,5,5,0]}>
          <ProductBlock
            guid={products[0].guid}
            image={products[0].images_url[0]}
            title={products[0].title_html}
            alt={products[0].title_html}
          />
        </Box>

        <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 4]} mb={[5, 5, 5, 0]}>
          <ProductBlock
            guid={products[1].guid}
            image={products[1].images_url[0]}
            title={products[1].title_html}
            alt={products[1].title_html}
          />
        </Box>

        <Box width={[1, 1, 1, 1 / 3]} px={[0, 0, 0, 4]} mb={[5, 5, 5, 0]}>
          <ProductBlock
            guid={products[2].guid}
            image={products[2].images_url[0]}
            title={products[2].title_html}
            alt={products[2].title_html}
          />
        </Box>
      </Flex>
    </div>
  )
}

AlsoBoughtBlock.propTypes = {
  title: PropTypes.string.isRequired,
  products: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired
}

AlsoBoughtBlock.defaultProps = {}

export const alsoBoughtBlockFragment = graphql`
  fragment alsoBoughtBlockFragment on AlsoBoughtBlock {
    id
    title
    products {
      id
      guid
      routeSegment
      title_html
      title
      images_url
    }
  }
`

export default AlsoBoughtBlock
