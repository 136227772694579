import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'

const BlueBulletStyles = css`
  ul li {
    ${tw`text-border-blue`}
  }

  ${tw`mr-16`}

  @media screen and (max-width: 64em) {
    ${tw`mr-0`}
  }
`

const BiancaBulletStyles = css`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      &:before { content: '- '; }
      ${tw`mb-2`}
    }
  }

  ${tw`mr-16`}

  @media screen and (max-width: 64em) {
    ${tw`mr-0`}
  }
`

const TextContainer = styled.div`
  ${tw`font-raleway text-lg text-blog-text leading-loose mb-10`}

  p {
    margin: 0;
  }

  h3 {
    ${tw`font-montserrat font-light text-3xl text-blog-text leading-loose mb-5`}
  }

  h4 {
    ${tw`font-montserrat font-light text-1xl text-blog-text leading-loose mb-5`}
  }

  a {
    ${tw`text-border-blue focus:underline hover:underline`}
  }

  ${props => (props.modification.includes('bluebullet')) ? BlueBulletStyles : null}
  ${props => (props.modification.includes('biancabullet')) ? BiancaBulletStyles : null}
`

const TextBlock = (props) => {
  const { modification, text } = props;
  return (
    <TextContainer dangerouslySetInnerHTML={{ __html: text }} modification={modification} />
  )
}

TextBlock.propTypes = {
  text: PropTypes.string.isRequired,
  modification: PropTypes.string
}

TextBlock.defaultProps = {
  modification: ''
}

export const textBlockFragment = graphql`
  fragment textBlockFragment on TextBlock {
    id
    guid
    modification
    text
  }
`

export default TextBlock
